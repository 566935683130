import React, { useState, useEffect, useCallback } from 'react';

const ROWS = 20;
const COLS = 30;
const TRANSITION_DURATION = 2000; // 2 seconds for smooth transition
const MEAN_BRIGHTNESS = 0.4; // Adjusted mean brightness for slightly darker colors
const STANDARD_DEVIATION = 0.2; // Adjusted standard deviation for color variations

interface Tile {
  color: string;
  brightness: number;
}

// Array of muddy pastel colors in HSL format for grayish blues, violets, and purples
const pastelColors = [
  // Muddy Blues
  'hsl(210, 30%, 35%)', // Darker Muddy Blue
  'hsl(210, 20%, 40%)', // Dusty Blue
  'hsl(200, 25%, 45%)', // Steel Blue
  'hsl(220, 30%, 30%)', // Deep Muddy Blue

  // Muddy Indigos
  'hsl(240, 30%, 35%)', // Darker Muddy Indigo
  'hsl(240, 20%, 40%)', // Dusty Indigo
  'hsl(230, 25%, 45%)', // Steel Indigo
  'hsl(250, 30%, 30%)', // Deep Muddy Indigo

  // Muddy Purples
  'hsl(260, 30%, 35%)', // Darker Muddy Purple
  'hsl(260, 20%, 40%)', // Dusty Purple
  'hsl(270, 25%, 45%)', // Steel Purple
  'hsl(280, 30%, 30%)', // Deep Muddy Purple

  // Muddy Violets
  'hsl(280, 30%, 35%)', // Darker Muddy Violet
  'hsl(280, 20%, 40%)', // Dusty Violet
  'hsl(290, 25%, 45%)', // Steel Violet
  'hsl(300, 30%, 30%)', // Deep Muddy Violet

  // Muddy Turquoises
  'hsl(190, 30%, 40%)', // Muddy Turquoise
  'hsl(180, 30%, 35%)', // Darker Muddy Turquoise
  'hsl(180, 20%, 40%)', // Dusty Turquoise
  'hsl(200, 25%, 30%)', // Deep Muddy Turquoise

  // Grays
  'hsl(0, 0%, 70%)',    // Light Muddy Grey
  'hsl(0, 0%, 55%)',    // Medium Muddy Grey
  'hsl(0, 0%, 40%)',    // Dark Muddy Grey
  'hsl(0, 0%, 30%)',    // Very Dark Muddy Grey

  // Extra Random Colors
  'hsl(320, 30%, 40%)', // Muddy Rose
  'hsl(340, 30%, 40%)', // Muddy Coral
  'hsl(60, 30%, 40%)',  // Muddy Olive
  'hsl(150, 30%, 40%)', // Muddy Teal
  'hsl(150, 20%, 40%)', // Dusty Teal
  'hsl(160, 30%, 40%)', // Muddy Mint

  'hsl(30, 100%, 50%)', // Muddy Mint
  'hsl(45, 100%, 50%)', // Muddy Mint

  'hsl(0, 00%, 100%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
  'hsl(0, 00%, 00%)', // Muddy Mint
];

// Function to randomly select a muddy pastel color
function generateRandomColor(): string {
  const randomIndex = Math.floor(Math.random() * pastelColors.length);
  return pastelColors[randomIndex];
}

function normalRandom(): number {
  let u = 0, v = 0;
  while (u === 0) u = Math.random(); // Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  const num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  return num * STANDARD_DEVIATION + MEAN_BRIGHTNESS;
}

function clamp(num: number, min: number, max: number): number {
  return Math.min(Math.max(num, min), max);
}

export default function TileWall() {
  const [tiles, setTiles] = useState<Tile[][]>([]);

  const generateTiles = useCallback(() => {
    return Array(ROWS).fill(null).map(() =>
      Array(COLS).fill(null).map(() => {
        const brightness = clamp(normalRandom(), 0, 1);
        return {
          brightness,
          color: generateRandomColor(), // Generate a random muddy pastel color
        };
      })
    );
  }, []);

  useEffect(() => {
    setTiles(generateTiles());

    const interval = setInterval(() => {
      setTiles(generateTiles());
    }, TRANSITION_DURATION);

    return () => clearInterval(interval);
  }, [generateTiles]);

  // Calculate the average brightness for the radial gradient
  const calculateGradientColors = () => {
    const averageBrightness = tiles.flat().reduce((acc, tile) => acc + tile.brightness, 0) / (ROWS * COLS);

    const redValue = Math.max(0, Math.log(averageBrightness + 0.01)); // Avoid log(0)
    const scaledRedValue = Math.min(255, Math.floor((redValue / Math.log(1.01)) * 255)); // Normalize

    return `rgba(${scaledRedValue}, 255, 255, ${averageBrightness})`;
  };

  const gradientColor = calculateGradientColors();

  return (
    <div className="tile-wall-container">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&display=swap');
          
          .tile-wall-container {
            width: 100vw;
            height: 100vh;
            overflow: hidden;
            position: relative;
            background-color: #000;
          }

          .tile-wall {
            display: grid;
            grid-template-columns: repeat(${COLS}, 1fr);
            grid-template-rows: repeat(${ROWS}, 1fr);
            height: 100%;
            width: 100%;
            filter: blur(250px); /* Increased blur */
            background-image: radial-gradient(circle, ${gradientColor} 30%, transparent 70%);
          }
          
          .tile {
            transition: background-color ${TRANSITION_DURATION}ms linear;
            box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
            border-radius: 4px; 
          }
          
          .centered-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: 'Cardo', serif;
            font-size: 3rem;
            font-weight: 700;
            color: white;
            text-shadow: 
              0 2px 4px rgba(0, 0, 0, 0.5),
              0 4px 8px rgba(0, 0, 0, 0.3),
              0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced text shadow for depth */
            z-index: 10;
          }

          .tile-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            pointer-events: none;
            z-index: 5;
          }

          .est-text {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Cardo', serif; /* or any other font you're using */
  font-size: 1rem; /* Adjust size as needed */
  color: white; /* Change color if needed */
  z-index: 10;
}
        `}
      </style>
      <div className="tile-wall">
        {tiles.map((row, i) =>
          row.map((tile, j) => (
            <div
              key={`${i}-${j}`}
              className="tile"
              style={{ backgroundColor: tile.color }} // Use the muddy pastel color
            />
          ))
        )}
      </div>
      <div className="tile-overlay"></div>
      <div className="centered-text">
        Kreysar.
      </div>
      <div className="est-text">
        <a 
        style={{ color: 'inherit', textDecoration: 'none' }}
        href="https://wakefield.fyi">est. 2024</a>
      </div>
    </div>
  );
}